* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  scroll-behavior: smooth;

  --mat-datepicker-calendar-container-background-color: #fff;
  --mat-sys-label-large-size: 1rem;
  --mat-select-panel-background-color: #fff;
  --mdc-radio-state-layer-size: 30px;
  --mat-menu-item-hover-state-layer-color: var(--header-bg-color);
  --mat-menu-item-label-text-color: #333;
  --mat-menu-container-color: #fff;
  --mat-autocomplete-background-color: #f7faff;
}

body {
  margin: 0;
  background: whitesmoke;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 17px;
  line-height: 1.25;
  color: var(--font-color);

  &.cookie-consent-active {
    overflow: hidden;
  }
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto Regular"),
    local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto Medium"),
    local("Roboto-Medium"),
    url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto Bold"),
    local("Roboto-Bold"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto Italic"),
    local("Roboto-Italic"),
    url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

#searchbox {
  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-flex {
    padding-top: 3px;
    height: 67px;
    display: flex;
    align-items: center;
  }
  .mat-mdc-form-field-infix {
    border-top: 0;
    width: 100%;
  }

  .mat-mdc-form-field-wrapper {
    padding: 0;
    margin: 0;
  }

  .mat-mdc-form-field-outline-start,
  .mat-mdc-form-field-outline-end {
    border-radius: 0;
  }
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-prefix {
  top: 0;
}

.mat-mdc-flat-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  color: var(--button-color) !important;
  background-color: var(--button-bg-color) !important;
}

.mat-mdc-select-panel-wrap > .mat-mdc-select-panel {
  max-height: 306px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: var(--button-bg-color);
  }
}

.mat-datepicker-content .mat-calendar {
  border: 1px solid #959595;
  border-radius: 10px;
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
}

.mat-mdc-button,
.mat-mdc-button .mdc-button__ripple {
  border-radius: 0 !important;
}

.g-backdrop {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6.8px);
  -webkit-backdrop-filter: blur(6.8px);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

gallery {
  background: transparent;
  background-color: white !important;
}

.cluster-marker {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  border-radius: 50%;
}

.cluster-count {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

#top-text {
  h2 {
    margin-bottom: 0.3rem;
  }

  p {
    margin-top: 0.7rem;
  }
}

.cookie-policy-list {
  margin: 0;
  font-size: 0.8rem;
  border-bottom: 1px solid #adadad;
  padding: 15px 10px;
  background: #fff;

  &:last-child {
    border-bottom: none;
  }

  tr {
    border: none;
  }
  td {
    padding: 2px;
  }

  tr:nth-of-type(odd) {
    background: #f5f5f5;
  }
}

img {
  vertical-align: top;
}
